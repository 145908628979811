import styled from '@xstyled/styled-components';

export const Layout = styled.div`
  background-color: bg;
`;

export const LayoutHeader = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const LayoutMain = styled.mainBox``;

export const LayoutFooter = styled.footer``;
